<template>
    <div style="background:#eee;padding:60px 50px;height:100%;">
        <Card :bordered="false">
            <p slot="title" style="font-size:20px;line-height:50px;height:50px;text-align:center;color:red;"><Icon type="ios-warning" size="30"/> 登录超时</p>
            
            <div style="margin-top:30px;sline-height:150%;height:200px;text-align:center;">
                <p>登录超时，请重新登录，如有疑问，请与系统管理员联系。</p>
                <br/> <br/> <br/>
             <!--   <a href="javascript:;" @click="goback"><Icon type="ios-arrow-back" size="20"/> 返回上一级</a> 
                <span style="margin:0 10px;">或</span>
                <a href="javascript:;" @click="checkout"><Icon type="ios-contact"  size="20"/> 使用其他账号重新登录</a>-->
                 <Button type="success" style="width:150px;" @click="checkout"><Icon type="ios-contact"  size="20"/> 重新登录</Button>
            </div>
        </Card>
    </div>
</template>
<script>
    export default {
        methods: {
            goback(){//返回上一级页面
              this.$parent.goto(parent.vue.preUrl);
            },
            checkout(){//打开登录窗口
            parent.vue.checkOut(true);
            }
        }
    }
</script>
